import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import Seo from "../components/misc/seo";
import IntegrationFilterSection from "../components/integrations/integrations-filter-section";
import TestimonialSection from "../components/layout/testimonial-section";
import SectionSeparator from "../components/layout/section-separator";
import WhyChooseStitch from "../components/layout/why-choose-stitch";
import { useVideoSelector } from "../hooks/VideoSelector";
import VideoContainer from "../components/misc/video-container";

const Destination = ({ data, pageContext }) => {
    const menus = pageContext.menus?.items;
    const destination = data.contentfulDataEndpoint;
    const bodyClasses = `data-warehouses data-warehouses_${destination.url}`;
    const selectedVideo = useVideoSelector("destination", [destination.type_id]);

    let pageTitle = `ETL your data into your ${destination.name} data warehouse`;
    let pageHeading = `ETL your data into your ${destination.name} data warehouse`;
    let pageDescription = `ETL your data into your ${destination.name} data warehouse.`;

    if (destination.name.includes("Warehouse") || destination.name.includes("Databricks")) {
        pageTitle = `ETL your data into your ${destination.name}`;
        pageHeading = `ETL your data into your ${destination.name}`;
        pageDescription = `ETL your data into your ${destination.name}.`;
    }

    if (destination.name.includes("S3")) {
        pageTitle = `ETL your data into your ${destination.name} data lake`;
        pageHeading = `ETL your data into your ${destination.name} data lake`;
        pageDescription = `ETL your data into your ${destination.name}.`;
    }

    return (
        <Layout
            menus={menus}
            headerType={pageContext.type}
            headerItems={[destination]}
            pageHeading={pageHeading}
            pageSubHeading={`${destination.description.description}`}
        >
            <Seo
                title={pageTitle}
                description={pageDescription}
                slug={`/data-warehouses/${destination.url}`}
                bodyClass={bodyClasses}
            />
            {selectedVideo && (
                <VideoContainer
                    videoEmbedCode={selectedVideo[0].embedCode.embedCode}
                    caption={selectedVideo[0].caption.caption}
                />
            )}
            <SectionSeparator />
            {destination.customer ? (
                <TestimonialSection
                    quote={destination.customer[0].quote.quote}
                    author={destination.customer[0].quote_name}
                    position={`${destination.customer[0].quote_title}, ${destination.customer[0].company_name}`}
                />
            ) : (
                <TestimonialSection
                    quote={`Stitch allowed us to set up a data pipeline within a day. It helped us avoid the hassle of building a data infrastructure team. The quick turnaround time allowed us to gather insights almost near real time.`}
                    author={`Ashok Varma`}
                    position={`Founder and CEO, ReportGarden`}
                />
            )}

            <SectionSeparator />
            <WhyChooseStitch />
            <IntegrationFilterSection
                heading={`Connect to your ecosystem of data sources`}
                paragraph={`Stitch integrates with leading databases and SaaS products. No API maintenance, ever, while you maintain full control over replication behavior.`}
            />
        </Layout>
    );
};

export default Destination;

export const query = graphql`
    query($slug: String!) {
        contentfulDataEndpoint(url: { eq: $slug }) {
            contentful_id
            type_id
            name
            url
            description {
                description
            }
            logo {
                file {
                    url
                }
            }
            square_logo {
                file {
                    url
                }
            }
            customLandingPageContent {
                customLandingPageContent
            }
            customer {
                company_name
                quote {
                    quote
                }
                quote_name
                quote_title
            }
            status
        }
    }
`;
